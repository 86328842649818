import { render, staticRenderFns } from "./corporateAdminForm.vue?vue&type=template&id=edc96ad6&scoped=true&"
import script from "./corporateAdminForm.vue?vue&type=script&lang=js&"
export * from "./corporateAdminForm.vue?vue&type=script&lang=js&"
import style0 from "./corporateAdminForm.vue?vue&type=style&index=0&id=edc96ad6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "edc96ad6",
  null
  
)

export default component.exports