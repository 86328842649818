<script>
//
//import Switches from "vue-switches";
import Multiselect from "vue-multiselect";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import {
  required, email, maxLength, minLength
} from "vuelidate/lib/validators";


/**
 * Advanced-form component
 */
export default {
  page: {
    title: "Add Corporate User",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {

    // Switches,
    Multiselect,
    Layout,
    PageHeader,

  },
  data() {
    return {
      title: "Add Corporate User",
      items: [
        {
          text: "Add Corporate User",
          href: "/",
        },
        {
          text: "Add",
          active: true,
        },
      ],

      status: '',
      submitted: false,
      showDismissibleAlert: false,
      loading: false,
      notificationText: "",
      alertvariant: "",
      //dateJoining:"",
      employeeTypeArr: [],
      empTypeID: "",
      empName: '',
      empEmailID: '',
      phoneNO: '',
      loginTypeArr: [
        {id: 1, name: "CloudKitch Admin"},
        {id: 2, name: "Restaurant"},
        {id: 3, name: "Corporate"},
        {id: 4, name: "Principle"},
      ],
      loginType: "",
      roles: [],
      role: "",
      editMode: false,
      restrictedBranchAccess:true,

    };
  },

  validations: {
    empName: {
      required,
    },
    empEmailID: {
      required,
      email,
    },
    phoneNO: {
      required,
      maxLength: maxLength(10),
      minLength: minLength(10),
    },
    loginType: {
      required
    },
    role: {
      required
    }

  },
  created() {
    let corporateID = this.$route.params.corporateID;
    let corpBranchID = this.$route.params.corpBranchID;
    if (corporateID) {
      sessionStorage.setItem('corporateID', corporateID);// Save to sessionStorage
    }
    if (corpBranchID) {
      sessionStorage.setItem('corpBranchID', corpBranchID);// Save to sessionStorage
    }
    if (this.$route.params.type == 'edit' && this.$route.params.admin_id) {
      this.editMode = true;
      sessionStorage.setItem('cam', this.$route.params.admin_id);
    }
  },
  methods: {
    loadRoleTypeData() {
      if (this.loginType) {
        this.loading = true;
        this.axios
            .get(this.$loggedRole + "/login-type-roles/" + this.loginType.id)
            .then((result) => {
              this.roles = result.data.data;
              this.loading = false;
            });

      }
    },
    submitEmployee(e) {

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true;
        let api;
        let formData = new FormData();
        if (this.editMode) {
          api = this.$loggedRole + "/updateCorporateAdmin";
          formData.append('admin_id', sessionStorage.getItem('cam'));
        } else {
          api = this.$loggedRole + "/addCorporateAdmin"
        }

        formData.append('emailID', this.empEmailID);
        formData.append('phoneNO', this.phoneNO);
        formData.append('name', this.empName);
        formData.append('corporateID', sessionStorage.getItem('corporateID'));
        formData.append('corpBranchID', sessionStorage.getItem('corpBranchID'));
        formData.append('login_type', this.loginType.id);
        formData.append('role', JSON.stringify(this.role));
        formData.append('restrictedBranchAccess',this.restrictedBranchAccess)
        e.preventDefault();
        this.axios.post(api, formData)
            .then((result) => {
              this.loading = false;
              this.$swal({
                text: result.data.message,
                icon: "success",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000
              });
              setTimeout(() => {
                this.$router.back();
              }, 2000);
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: error.response.data.message,
                icon: "error",
              });
            });
      }
    },

    getEmployeeDesignation() {
      this.axios.post(this.$loggedRole + "/getEmployeeDesignation", {
            loginTypeID: 3,
            corpBranchID: sessionStorage.getItem('corpBranchID')
          } // 3-> corporte
      )
          .then((result) => {
            this.employeeTypeArr = result.data.data;

          });
    },
    getEmployeeDetail() {
      if (sessionStorage.getItem('cam') && sessionStorage.getItem('corporateID') && sessionStorage.getItem('corpBranchID')) {
        this.loading = true;
        this.axios
            .get(this.$loggedRole + "/get-corporate-admin/" + sessionStorage.getItem('cam') + "/" + sessionStorage.getItem('corporateID') + "/" + sessionStorage.getItem('corpBranchID'))
            .then((result) => {
              this.loading = false;
              this.empName = result.data.data.name;
              this.empEmailID = result.data.data.emailID;
              this.phoneNO = result.data.data.phoneNO;
              this.loginType = result.data.data.login_type;
              this.restrictedBranchAccess = result.data.data.restrictedBranchAccess;
              this.loadRoleTypeData();
              this.role = result.data.data.role;
            }).catch((error) => {
          this.loading = false;
          this.$swal({
            text: error.response.data.message,
            icon: "error",
          });
        });
      }
    },

  },

  mounted() {
    let corporateID = this.$route.params.corporateID;
    let corpBranchID = this.$route.params.corpBranchID;
    if (corporateID) {
      sessionStorage.setItem('corporateID', corporateID);// Save to sessionStorage
    }
    if (corpBranchID) {
      sessionStorage.setItem('corpBranchID', corpBranchID);// Save to sessionStorage
    }
    if (this.$route.params.type == 'edit' && sessionStorage.getItem('cam') && sessionStorage.getItem('corporateID') && sessionStorage.getItem('corpBranchID')) {
      this.editMode = true;
      this.getEmployeeDetail();
    }

  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <div class="alertMsg">
      <b-alert v-model="showDismissibleAlert" variant="success" dismissible>
        Employee Registered Successfully!
      </b-alert>
    </div>
    <!-- <div class="alertMsg">
      <b-alert v-model="showDismissibleAlert" variant="alertvariant" dismissible>
          {{notificationText}}
      </b-alert>
    </div> -->

    <form @submit.prevent="submitEmployee" method="post">

      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 mb-3">
                  <label class="form-label" for="formrow-empName-input">Name *</label>
                  <input type="text" class="form-control" id="formrow-name-input" v-model="empName"
                         :class="{
                                'is-invalid': submitted && $v.empName.$error,
                            }"
                  >
                  <div
                      v-if="submitted && $v.empName.$error"
                      class="invalid-feedback"
                  >
                            <span v-if="!$v.empName.required"
                            >This value is required.</span
                            >
                  </div>
                </div>


                <div class=" col-6">
                  <label class="form-label" for="formrow-empEmailID-input">EmailID *</label>
                  <input type="text" class="form-control" id="formrow-empEmailID-input" v-model="empEmailID"
                         :class="{
                                    'is-invalid': submitted && $v.empEmailID.$error,
                                }"
                  >
                  <div
                      v-if="submitted && $v.empEmailID.$error"
                      class="invalid-feedback"
                  >
                                <span v-if="!$v.empEmailID.required"
                                >This value is required.</span
                                >
                  </div>
                </div>
                <div class="col-6">
                  <label class="form-label">Phone No.*</label>
                  <input class="form-control" type="number" min="0"
                         im-insert="true" v-model="phoneNO"
                         :class="{
                                'is-invalid': submitted && $v.phoneNO.$error,
                            }">

                  <div
                      v-if="submitted && $v.phoneNO.$error"
                      class="invalid-feedback"
                  >
                            <span v-if="!$v.phoneNO.required"
                            >This value is required.</span
                            >
                    <span v-if="!$v.phoneNO.maxLength"
                    >This value is too long. It should have 10 digits</span
                    >
                    <span v-if="!$v.phoneNO.minLength"
                    >This value length is invalid. It should be
                              10 digits</span
                    >
                  </div>
                </div>
                <div class="mb-3 col-6">
                  <label> Login Type<sup>*</sup> : </label>
                  <multiselect
                      v-model="loginType"
                      :options="loginTypeArr"
                      :show-labels="false"
                      track-by="id"
                      label="name"
                      :class="{'is-invalid': submitted && $v.loginType.$error,}"
                      @input="loadRoleTypeData()"
                  >
                  </multiselect>
                  <div v-if="submitted && $v.loginType.$error" class="invalid-feedback">
                    <span v-if="!$v.loginType.required">This value is required.</span>
                  </div>
                </div>
                <div class="mb-3 col-6">
                  <label> Role<sup>*</sup> :</label>
                  <multiselect
                      v-model="role"
                      :options="roles"
                      :show-labels="false"
                      track-by="id"
                      label="name"
                      :class="{'is-invalid': submitted && $v.loginType.$error,}"
                  >
                  </multiselect>
                  <div v-if="submitted && $v.role.$error" class="invalid-feedback">
                    <span v-if="!$v.role.required">This value is required.</span>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <label>Access to current branch only</label>
                  <br />
                  <div class="form-check form-switch form-switch-md mb-3" dir="ltr">
                    <input type="checkbox" class="form-check-input" id="customSwitchsizemd" v-model="restrictedBranchAccess">
                  </div>
                </div>
              </div>


              <!-- <div class="col-md-4 mb-3">
                  <label>Date of Joining</label>
                  <br />
                  <input type="date" class="form-control" id="formrow-licenseNO-input" v-model="dateJoining">

              </div> -->
              <div class=" row" style="float:right;">
                <b-spinner v-if="loading"
                           class="m-2 col-3"
                           variant="primary"
                           role="status"
                ></b-spinner>
                <button type="submit" class="col-3 btn btn-themeYellow w-md waves-effect waves-light"
                        :disabled="loading">Submit
                </button>
              </div>

            </div>
          </div>

        </div>


      </div>

    </form>

  </Layout>
</template>

<style lang="scss" scoped>

img.preview {
  width: 100px;
  height: 100px;
  background-color: white;
  border: 1px solid #DDD;
  padding: 5px;
  object-fit: contain;
  margin-top: 6px;
}

.alertMsg {
  position: fixed;
  right: 0px;
  z-index: 500;
  top: 79px;
  width: 22%;
}
</style>


